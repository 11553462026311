.App {
    background-color: #ffdcba;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #5d3943;
}

.App-home {
    text-align: center;
}